import React, { forwardRef } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const FluentChevronDown = forwardRef(
  (
    { fillColor = '#212121', ...props }: { fillColor?: string } & SvgProps,
    ref
  ) => (
    <Svg
      width={24}
      height={24}
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <Path
        d='M4.293 8.293a1 1 0 011.414 0L12 14.586l6.293-6.293a1 1 0 111.414 1.414l-7 7a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.414z'
        fill={fillColor}
      />
    </Svg>
  )
)

export default FluentChevronDown
